
import { ComponentPublicInstance, computed, defineComponent, reactive, ref } from 'vue';
import UIFilterSelect from '@/components/UI/UIFilterSelect.vue';
import { canchasState, especiesState, estadoRumasState } from '@/store/mantenedores.store';
import { cleanSource } from '@/utils';
import { userState } from '@/store/user.store';

export default defineComponent({
  name: 'TableFilter',
  components: {
    'ui-filter-select': UIFilterSelect
  },
  emits: ['filter', 'unfilter'],
  setup(_props, { emit }) {
    const user = computed(() => userState.value);
    const especieRef = ref<ComponentPublicInstance<typeof UIFilterSelect> | null>(null);
    const canchaRef = ref<ComponentPublicInstance<typeof UIFilterSelect> | null>(null);
    const estadoRef = ref<ComponentPublicInstance<typeof UIFilterSelect> | null>(null);

    const canchas = computed(() => {
      const c = [...cleanSource(canchasState.value.map(el => ({ id: el._id, label: `${el.codigo} ${el.descripcion}` })))];
      return [
        c.pop(),
        c.pop(),
        ...c
      ];
    });
    const especies = computed(() => especiesState.value.map(el => ({ id: el._id, label: el.descripcion })));
    const estados = computed(() => estadoRumasState.value.map(el => ({ id: el._id, label: el.descripcion })));
    const filter = reactive<{[k: string]: any}>({
      especie: null,
      cancha: null,
      estado: null
    });
    const unfilter = () => {
      filter.especie = null;
      filter.cancha = null;
      filter.estado = null;
      especieRef.value?.clearValue();  
      canchaRef.value?.clearValue(); 
      estadoRef.value?.clearValue(); 
      emit('unfilter');
    };
    
    return {
      especieRef,
      canchaRef,
      estadoRef,
      canchas,
      estados,
      especies,
      filter,
      unfilter,
      user
    };
  }
});
