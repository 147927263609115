import {readonly, ref} from "vue";
import {makeRequest, METHODS, sendFileToServer} from "@/utils/apiUtils";
import {IRumaApi} from "@/models/Mantenedores";


export const useRumaImage = () => {

	const rumaId = ref<string>('');
	const rumaImage = ref<any>(null);

	const setRumaImage = async (id: string, file: any) => {
		rumaId.value = id;
		rumaImage.value = file;
		return await uploadImage();
	};

	const clearRumaImage = () => {
		rumaId.value = '';
		rumaImage.value = null;
	};

	const uploadImage = async () => {
		const url = `/ruma/upload-image/${rumaId.value}`;
		const response = await sendFileToServer<{ id: string; ruma: any }>(url, rumaImage.value, 'image');
		return response.payload;
	};

	const mapRumas = ref<any[]>([]);
	const getGeoRumas = async (canchaId?: string) => {
		mapRumas.value = [];
		const url = '/rumas-map';
		const response = await makeRequest<any>(METHODS.GET, canchaId ? `${url}?cancha=${canchaId}` : url, true );
		mapRumas.value = response.payload;
	};

	const resetGeoData = async (rumaId: string) => {
		const url = `/ruma/reset-geo/${rumaId}`;
		const response = await makeRequest<any>(METHODS.PATCH, url, true );
		return response.payload;
	};

	return {
		rumaId: readonly(rumaId),
		rumaImage: readonly(rumaImage),
		setRumaImage,
		clearRumaImage,
		getGeoRumas,
		resetGeoData,
		mapRumas: readonly(mapRumas)
	};
};
